#start-container {
  margin: 3rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.home__code-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 55%;
  width: 100%;
}

.home__code-group > p {
  font-size: 2.4rem;
}

.home__input {
  height: 4rem;
  width: 100%;
  font-size: 2.4rem;
  text-align: center;
  border: 0.4rem solid #ffcb37;
  border-radius: 2.5rem;
  text-transform: uppercase;
}

.home__button {
  border: none;
  background-color: #ffcb37;
  color: white;
  padding: 0.8rem;
  border-radius: 2.5rem;
  width: 100%;
  font-size: 2.4rem;
}
