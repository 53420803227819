.set__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40rem;
  flex-direction: column;
  padding: 0 2rem;
  text-align: center;
}

.set__container__text {
  padding: 1rem 0;
}

.set__container__text > p {
  font-family: 'Bumble Light';
  font-size: 2rem;
  padding: 0.5rem 0;
}

.set__container__text > p > span {
  font-family: 'Bumble Bold';
}

.set__button {
  border: none;
  background-color: #ffcb37;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.8rem;
  border-radius: 50px;
  width: 100%;
  height: 4rem;
}

.set__link__container {
  border: 0.4rem solid #ffcb37;
  padding: 0.2rem;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 4rem;
  width: 100%;
}

.set__link__button {
  background-color: #ffcb37;
  color: white;
  border: none;
  font-size: 1.8rem;
  height: 100%;
  font-weight: bold;
  border-radius: 50px;
  width: 25%;
  margin-right: 0.1rem;
}

.set__input {
  height: 100%;
  width: 75%;
  font-size: 2rem;
  text-align: center;
  border: none;
  border-radius: 50px;
  text-overflow: ellipsis;
  margin-right: 2rem;
}

.set__input:focus {
  outline: none;
}

p.set__code {
  font-size: 3rem;
  font-family: Lato;
}

.results__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  max-width: 100%;
}

.results__list__item {
  display: flex;
  flex-direction: column;
}

.results__list__item > p {
  margin-bottom: 1.5rem;
}

.results__img {
  padding: 1rem;
  border-radius: 2rem;
}

.results {
  text-align: center;
}

.results > p {
  font-weight: bold;
  font-size: 2.2rem;
  margin: 3rem 0;
}
