@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bumble';
  src: url(../../static/media/b.5e397b89.ttf) format('truetype');
}

@font-face {
  font-family: 'Bumble Bold';
  src: url(../../static/media/b-bold.8269d0ac.ttf) format('truetype');
}

@font-face {
  font-family: 'Bumble Light';
  src: url(../../static/media/b-light.7ffff033.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

html {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 2.1vh;
  scroll-behavior: smooth;
}

@media (max-width: 29em) {
  html {
    font-size: 3.2vw;
  }
}

body {
  max-width: 40rem;
  overflow-x: hidden;
}

h1 {
  margin: 0.5rem;
  color: #ffcb37;
  font-family: 'Bumble';
  font-weight: 100;
  font-size: 3rem;
  text-transform: lowercase;
  cursor: pointer;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

main {
  width: 29.4rem;
}

.top-level-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.app__code {
  color: gray;
  width: 4.8rem;
}

.app__button {
  color: white;
  background-color: gray;
  border-radius: 50px;
  padding: 0.3rem 0.6rem;
  border: none;
  font-size: 0.7rem;
  width: 4.8rem;
}

.home {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home__h1 {
  margin: 2rem 0 3rem 0;
  font-size: 4rem;
}

.home__text {
  margin: 2rem 0;
}

.home__text__p {
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

#start-app {
  cursor: pointer;
  width: 80%;
}

.footer__button {
  border: none;
  background-color: #ffcb37;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
  width: 4.8rem;
}

footer {
  position: fixed;
  bottom: 0;
}

.modal {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}

.modal > a {
  color: #ffcb37;
  text-decoration: none;
}

.modal__close {
  max-width: 20rem;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.conversion {
  display: none;
}

.app__loader {
  display: flex;
  justify-content: center;
}

@keyframes flyright {
  to {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(500%) rotate(90deg);
            transform: translateX(500%) rotate(90deg);
  }
}

@-webkit-keyframes flyright {
  to {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(500%) rotate(90deg);
            transform: translateX(500%) rotate(90deg);
  }
}

@keyframes flyleft {
  to {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-500%) rotate(-90deg);
            transform: translateX(-500%) rotate(-90deg);
  }
}

@-webkit-keyframes flyleft {
  to {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-500%) rotate(-90deg);
            transform: translateX(-500%) rotate(-90deg);
  }
}

@keyframes flyup {
  0% {
    -webkit-transform: none;
            transform: none;
  }

  10% {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
}

@-webkit-keyframes flyup {
  0% {
    -webkit-transform: none;
            transform: none;
  }

  10% {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
}

@-webkit-keyframes normal {
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes normal {
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes normalright {
  70% {
    -webkit-transform: translateX(-10px) rotate(-1deg);
            transform: translateX(-10px) rotate(-1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes normalright {
  70% {
    -webkit-transform: translateX(-10px) rotate(-1deg);
            transform: translateX(-10px) rotate(-1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes normalleft {
  70% {
    -webkit-transform: translateX(10px) rotate(1deg);
            transform: translateX(10px) rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes normalleft {
  70% {
    -webkit-transform: translateX(10px) rotate(1deg);
            transform: translateX(10px) rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.swipe__child {
  position: absolute;
  top: 0;
  left: 0;
}

.card-container--parent {
  position: relative;
  height: 40.8rem;
  width: 29.4rem;
  overflow: hidden;
  border-radius: 15px;
  cursor: grab;
}

.card-container--parent:active {
  cursor: grabbing;
}

.card-container--child {
  background-color: #fff8d8;

  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}

.info-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0rem 2.5rem;
  margin: 1.4rem 0;
  color: #454650;
}

.info-container__list {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  list-style-type: none;
  flex-wrap: wrap;
}

.info-container__list > li {
  background-color: #ffedb4;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  color: #454650;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0.35rem 0.6rem 0.35rem 0rem;
}

.info-container__lower {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.1rem 2rem;
  height: 16rem;
}

.info-container__lower > h2 {
  font-size: 1.6rem;
  color: #454650;
  margin-top: 0.6rem;
  margin-bottom: 2.4rem;
}

.info-container > p {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.info-container__lower__progress {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 2.2rem;
  font-family: 'Bumble Light';
  position: absolute;
  z-index: 5;

  cursor: pointer;

  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.hex {
  align-self: center;

  position: relative;
  width: 5.6rem;
  height: 3.8rem;
  border-radius: 1em/0.5em;
  background: #ffcb37;
  transition: opacity 0.5s;
}
.hex:before,
.hex:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}
.hex:before {
  -webkit-transform: rotate(60deg); /*Added for IE9*/
  transform: rotate(60deg);
}
.hex:after {
  -webkit-transform: rotate(-60deg); /*Added for IE9*/
  transform: rotate(-60deg);
}

img {
  height: 100%;
  width: 100%;
}

.like-dislike__container {
  display: flex;
  justify-content: space-between;
  width: 60%;
  align-items: center;
  width: 100%;
}

.like-dislike {
  cursor: pointer;
  background-color: #ffc422;
  border: none;
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.like::before,
.like::after,
.dislike::before,
.dislike::after {
  content: '';
  background-color: white;
  width: 0.4rem;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
}

.like::before {
  height: 1rem;
  -webkit-transform: rotate(-45deg) translate(-0.4rem, -0.2rem);
          transform: rotate(-45deg) translate(-0.4rem, -0.2rem);
}

.like::after {
  height: 2rem;
  -webkit-transform: rotate(45deg) translate(0.3rem, -0.4rem);
          transform: rotate(45deg) translate(0.3rem, -0.4rem);
}

.dislike::before {
  height: 2.2rem;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.dislike::after {
  height: 2.2rem;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.card__scroll {
  border-radius: 25px;
  width: 0.3rem;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background-color: rgba(255, 255, 255, 0.424);
  z-index: 100;
  float: right;
  height: 6rem;
}

.card__scroll__bar {
  border-radius: 25px;
  width: 100%;
  background-color: white;
  position: relative;
  height: 1.2rem;
}

.card__normal {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.card__shrink {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.err__button {
  position: absolute;
  z-index: -100;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.set__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40rem;
  flex-direction: column;
  padding: 0 2rem;
  text-align: center;
}

.set__container__text {
  padding: 1rem 0;
}

.set__container__text > p {
  font-family: 'Bumble Light';
  font-size: 2rem;
  padding: 0.5rem 0;
}

.set__container__text > p > span {
  font-family: 'Bumble Bold';
}

.set__button {
  border: none;
  background-color: #ffcb37;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.8rem;
  border-radius: 50px;
  width: 100%;
  height: 4rem;
}

.set__link__container {
  border: 0.4rem solid #ffcb37;
  padding: 0.2rem;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 4rem;
  width: 100%;
}

.set__link__button {
  background-color: #ffcb37;
  color: white;
  border: none;
  font-size: 1.8rem;
  height: 100%;
  font-weight: bold;
  border-radius: 50px;
  width: 25%;
  margin-right: 0.1rem;
}

.set__input {
  height: 100%;
  width: 75%;
  font-size: 2rem;
  text-align: center;
  border: none;
  border-radius: 50px;
  text-overflow: ellipsis;
  margin-right: 2rem;
}

.set__input:focus {
  outline: none;
}

p.set__code {
  font-size: 3rem;
  font-family: Lato;
}

.results__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  max-width: 100%;
}

.results__list__item {
  display: flex;
  flex-direction: column;
}

.results__list__item > p {
  margin-bottom: 1.5rem;
}

.results__img {
  padding: 1rem;
  border-radius: 2rem;
}

.results {
  text-align: center;
}

.results > p {
  font-weight: bold;
  font-size: 2.2rem;
  margin: 3rem 0;
}

#start-container {
  margin: 3rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.home__code-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 55%;
  width: 100%;
}

.home__code-group > p {
  font-size: 2.4rem;
}

.home__input {
  height: 4rem;
  width: 100%;
  font-size: 2.4rem;
  text-align: center;
  border: 0.4rem solid #ffcb37;
  border-radius: 2.5rem;
  text-transform: uppercase;
}

.home__button {
  border: none;
  background-color: #ffcb37;
  color: white;
  padding: 0.8rem;
  border-radius: 2.5rem;
  width: 100%;
  font-size: 2.4rem;
}

