.swipe__child {
  position: absolute;
  top: 0;
  left: 0;
}

.card-container--parent {
  position: relative;
  height: 40.8rem;
  width: 29.4rem;
  overflow: hidden;
  border-radius: 15px;
  cursor: grab;
}

.card-container--parent:active {
  cursor: grabbing;
}

.card-container--child {
  background-color: #fff8d8;

  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}

.info-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0rem 2.5rem;
  margin: 1.4rem 0;
  color: #454650;
}

.info-container__list {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  list-style-type: none;
  flex-wrap: wrap;
}

.info-container__list > li {
  background-color: #ffedb4;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  color: #454650;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0.35rem 0.6rem 0.35rem 0rem;
}

.info-container__lower {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.1rem 2rem;
  height: 16rem;
}

.info-container__lower > h2 {
  font-size: 1.6rem;
  color: #454650;
  margin-top: 0.6rem;
  margin-bottom: 2.4rem;
}

.info-container > p {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.info-container__lower__progress {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 2.2rem;
  font-family: 'Bumble Light';
  position: absolute;
  z-index: 5;

  cursor: pointer;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hex {
  align-self: center;

  position: relative;
  width: 5.6rem;
  height: 3.8rem;
  border-radius: 1em/0.5em;
  background: #ffcb37;
  transition: opacity 0.5s;
}
.hex:before,
.hex:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}
.hex:before {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg); /*Added for IE9*/
  transform: rotate(60deg);
}
.hex:after {
  -webkit-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg); /*Added for IE9*/
  transform: rotate(-60deg);
}

img {
  height: 100%;
  width: 100%;
}

.like-dislike__container {
  display: flex;
  justify-content: space-between;
  width: 60%;
  align-items: center;
  width: 100%;
}

.like-dislike {
  cursor: pointer;
  background-color: #ffc422;
  border: none;
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.like::before,
.like::after,
.dislike::before,
.dislike::after {
  content: '';
  background-color: white;
  width: 0.4rem;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
}

.like::before {
  height: 1rem;
  transform: rotate(-45deg) translate(-0.4rem, -0.2rem);
}

.like::after {
  height: 2rem;
  transform: rotate(45deg) translate(0.3rem, -0.4rem);
}

.dislike::before {
  height: 2.2rem;
  transform: rotate(45deg);
}

.dislike::after {
  height: 2.2rem;
  transform: rotate(-45deg);
}

.card__scroll {
  border-radius: 25px;
  width: 0.3rem;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background-color: rgba(255, 255, 255, 0.424);
  z-index: 100;
  float: right;
  height: 6rem;
}

.card__scroll__bar {
  border-radius: 25px;
  width: 100%;
  background-color: white;
  position: relative;
  height: 1.2rem;
}

.card__normal {
  transition: transform 0.3s;
  transform: scale(1);
}

.card__shrink {
  transform: scale(0.9);
}

.err__button {
  position: absolute;
  z-index: -100;
  left: 50%;
  transform: translateX(-50%);
}
