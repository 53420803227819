@keyframes flyright {
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateX(500%) rotate(90deg);
  }
}

@-webkit-keyframes flyright {
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateX(500%) rotate(90deg);
  }
}

@keyframes flyleft {
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-500%) rotate(-90deg);
  }
}

@-webkit-keyframes flyleft {
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-500%) rotate(-90deg);
  }
}

@keyframes flyup {
  0% {
    transform: none;
  }

  10% {
    transform: translateY(-20%);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-200%);
  }
}

@-webkit-keyframes flyup {
  0% {
    transform: none;
  }

  10% {
    transform: translateY(-20%);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-200%);
  }
}

@-webkit-keyframes normal {
  100% {
    transform: rotate(0deg);
  }
}

@keyframes normal {
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes normalright {
  70% {
    transform: translateX(-10px) rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes normalright {
  70% {
    transform: translateX(-10px) rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes normalleft {
  70% {
    transform: translateX(10px) rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes normalleft {
  70% {
    transform: translateX(10px) rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
