@font-face {
  font-family: 'Bumble';
  src: url('./fonts/b.ttf') format('truetype');
}

@font-face {
  font-family: 'Bumble Bold';
  src: url('./fonts/b-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Bumble Light';
  src: url('./fonts/b-light.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

html {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 2.1vh;
  scroll-behavior: smooth;
}

@media (max-width: 29em) {
  html {
    font-size: 3.2vw;
  }
}

body {
  max-width: 40rem;
  overflow-x: hidden;
}

h1 {
  margin: 0.5rem;
  color: #ffcb37;
  font-family: 'Bumble';
  font-weight: 100;
  font-size: 3rem;
  text-transform: lowercase;
  cursor: pointer;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

main {
  width: 29.4rem;
}

.top-level-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.app__code {
  color: gray;
  width: 4.8rem;
}

.app__button {
  color: white;
  background-color: gray;
  border-radius: 50px;
  padding: 0.3rem 0.6rem;
  border: none;
  font-size: 0.7rem;
  width: 4.8rem;
}

.home {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home__h1 {
  margin: 2rem 0 3rem 0;
  font-size: 4rem;
}

.home__text {
  margin: 2rem 0;
}

.home__text__p {
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

#start-app {
  cursor: pointer;
  width: 80%;
}

.footer__button {
  border: none;
  background-color: #ffcb37;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
  width: 4.8rem;
}

footer {
  position: fixed;
  bottom: 0;
}

.modal {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}

.modal > a {
  color: #ffcb37;
  text-decoration: none;
}

.modal__close {
  max-width: 20rem;
  margin-left: 50%;
  transform: translateX(-50%);
}

.conversion {
  display: none;
}

.app__loader {
  display: flex;
  justify-content: center;
}
